import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CounterV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__counterup-area section-bg-1 pt-120 pb-70">
			  <div className="container">
			    <div className="row">
			      <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">					  
					  <i class="fa-solid fa-star"></i>
			          </div>
			          <h1><span className="counter">11</span><span className="counterUp-icon">+</span> </h1>
			          <h6>Clients</h6>
			        </div>
			      </div>
			      <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">
					  <i class="fa-duotone fa-solid fa-shirt"></i>
			          </div>
			          <h1><span className="counter">250</span><span className="counterUp-letter">K</span><span className="counterUp-icon">+</span> </h1>
			          <h6>Products</h6>
			        </div>
			      </div>
			      <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">
					  <i class="fa-brands fa-first-order-alt"></i>
			          </div>
			          <h1><span className="counter">9</span><span className="counterUp-icon">+</span> </h1>
			          <h6>Top Brand</h6>
			        </div>
			      </div>
			      <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">
					  <i class="fa-solid fa-user"></i>
			          </div>
			          <h1><span className="counter">25</span><span className="counterUp-icon">+</span> </h1>
			          <h6>Team Member</h6>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default CounterV1