import React from 'react';
import Navbar from './global-components/navbar';
import VideoV4 from './section-components/video-v4';
import Aboutv1 from './section-components/about-v1';
import Counter from './section-components/counter-v1';  
import Featuresv1 from './section-components/features-v1';
import ProSlider from './section-components/product-slider-v1';  
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Home_V5 = () => {
    return <div>
        <Navbar CustomClass="ltn__header-transparent gradient-color-2" /> 
        <VideoV4 />
        <Aboutv1 />
        <Counter />     
        <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        {/* <ProSlider />  */}
        <Testimonial />
        <BlogSlider customClass="section-subtitle-2"/>
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home_V5

