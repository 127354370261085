import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__service-area section-bg-1 pt-15 pb-70 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">					 
						<h1 className="section-title">Why Choose Wepeople</h1>
					</div>
					<h6> 
 
Wepeople is one of the most renowned, capable & efficient sourcing partner in Bangladesh. We have a very strong raw materials sourcing team, technically sound quality personnel’s, expert product development team are standby to serve your inquiries. Also we had a proven business relationships with reputed manufacturers of all types of woven, sweaters & knit garments. We satisfy our customers by ensuring on time shipment, providing right quality products what they really looking for.</h6>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i class="fa-solid fa-list"></i></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/#">Expertise</Link></h3>
						<p>With a team of seasoned professionals who understand the intricacies of the apparel industry, we offer expert guidance and support throughout the sourcing journey.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i class="fa-solid fa-list"></i></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/#">Global Network</Link></h3>
						<p>Leveraging our extensive network of trusted manufacturers, we have the flexibility to source a diverse range of products, meeting the unique needs and specifications of our global clients.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i class="fa-solid fa-list"></i></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/#">Quality Assurance</Link></h3>
						<p>Quality is at the forefront of everything we do. We meticulously vet each manufacturer to ensure compliance with international standards and regulations, guaranteeing the highest quality products for our clients. Not only that, we’ve a strong team to control the quality standard.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i class="fa-solid fa-list"></i></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/#">Transparency</Link></h3>
						<p>We believe in building transparent and honest relationships with our clients and suppliers. From pricing to production timelines, you can trust us to provide clear and accurate information every step of the way.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i class="fa-solid fa-list"></i></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/#">Sustainability</Link></h3>
						<p>As stewards of the environment, we are committed to promoting sustainability throughout the supply chain. We work closely with eco-conscious manufacturers to minimize environmental impact and promote ethical practices.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i class="fa-solid fa-list"></i></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/#">Strengths</Link></h3>
						<p>We’ve multiple extensive expertise Knit, Woven & Sweater factories which help us to ship the goods on time. Also we’ve experts quality team who ensure goods quality to satisfy clients.</p>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ServiceV5