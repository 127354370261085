import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h1 className="section-subtitle section-subtitle-2 ltn__secondary-color">Who we're</h1>			      
			        </div>
					<h6>We people, a symbol of dependence and trust in the apparel sourcing field in Bangladesh. With an unwavering commitment to quality, reliability, and innovation, we have earned the reputation as one of the best apparel sourcing company in the country.</h6>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/21.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="#">Our Mission</Link></h3>
			            <p>To provide our business partners wonderful support, service and state-of-the-art designs which sourced in the most ethical way. We endeavor to be an institute where integrity, transparency, customer contentment and audacity guide us to become the most favored business associate and establishment in the industry.</p>			            
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/22.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="#">Our Vision</Link></h3>
			            <p>Be the trendiest global enterprise providing state-of-the-art fashion solutions in the utmost decent, and trustworthy technique. Ultimate customers satisfaction will be our peak priority.</p>
			           
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="#">Our Markets</Link></h3>
			            <p>Partnering with top brands, we ensure that every piece reflects excellence and style. Whether it’s fashion-forward designs or timeless classics, our commitment to quality and reliability makes us the preferred choice for international garment distribution. Let us bring the best in fashion to your doorstep, anywhere in the world.</p>
			            
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default FeaturesV1