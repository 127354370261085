import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120 pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
			          <img src={publicUrl+"assets/img/web/homeAbout.jpg"} alt="About Us Image" />
			           
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-20">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
			            <h1 className="section-title">One of the Fastest Garments Sourcing From Bangladesh<span>.</span></h1>
			            <p>Wepoeple Sourcing, a symbol of dependence and trust in the apparel sourcing field in Bangladesh. With an unwavering commitment to quality, reliability, and innovation, we have earned the reputation as one of the best apparel sourcing company in the country.</p>
			          </div>
			          <ul className="ltn__list-item-half">
			            <li>
			              <i className="flaticon-home-2" />
						  Affordable Pricing
			            </li>
			            <li>
			              <i className="flaticon-mountain" />
			              Top Quality
			            </li>
			            <li>
			              <i className="flaticon-heart" />
						  On time Production
			            </li>
			            
			          </ul>
			           
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV1